import React, {useState} from 'react';
import '../../App.css';
import styled from "styled-components";
import Header from "../../components/Header";
import EditUserNameDialog from "./components/EditNameDialog";
import MenuIcon from "../../components/MenuIcon";
import UserIcon from "../../assets/icons/user.svg";
import Footer from "../../components/Footer";
import MadeBy from "../../components/MadeBy";
import HelpIcon from "../../assets/icons/help.svg";
import {Link} from "@reach/router";
import Button from "@material-ui/core/Button";

export const Settings = (props) => {
    const [editNameDialog, setEditNameDialog] = useState(false);
    return (
        <Container className="DarkMode">
            <Header title={"Settings"}/>
            <ContentContainer>
                <MenuIcon
                    src={UserIcon}
                    title={"Change Name"}
                    path={'#'}
                    onSelect={()=>setEditNameDialog(true)}
                />
                <MenuIcon
                    src={HelpIcon}
                    title={"Help"}
                    path={'/help'}
                />
            </ContentContainer>
            <EditUserNameDialog
                open={editNameDialog}
                onClose={()=>{setEditNameDialog(false)}}
            />
            <MadeBy/>
            <Button
                variant="text"
                color="secondary"
                onClick={() => window.location = 'mailto:quraan.io.za@gmail.com'}
            >
                Email Us
            </Button>
            <Footer bottomBar/>
        </Container>

    );
}

export default Settings;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const ContentContainer = styled.div`
    width: 90%;
    height: 90%;
    box-sizing:border-box;
    margin: 5% 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: hidden;
`;

const Email = styled.a`
  text-align: center;
  color: white;
  font-size: calc(10px + 2vmin);
`;