import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import styled from "styled-components";
import ReactPlayer from "react-player"
import MadeBy from "../components/MadeBy";

const Help = () => {

    return (
        <Container className="DarkMode">
            <Header title={"Help"} back/>
            <ContentContainer>
                <HelpText>
                    <ReactPlayer
                    width={"100%"}
                    url="https://youtu.be/nr1SBPBisrI"
                  />
                  <p>If you need to enter your name every time the app starts, make sure your browser is not in Private / Incognito Mode!</p>

                    <h4>&#55358;&#56596; What is the Quraan Tracker App&#10067;</h4>
                    <p>&#55357;&#56393;&#55356;&#57340; You can think of it as WhatsApp for:<br/>
                        Quraan Khatams &#9989;<br/>
                        Tasbeehs &#9989;</p>

                    <SubP>The app will allow you to share a Quraan/Tasbeeh Khatam with a group of people. They can then
                        reserve
                        which Juz they would like to read it submit the number of Tasbeeh they've read.</SubP>

                    <SubP>&#9989; No more copying/pasting khatams<br/>
                        &#9989; No more confusion of who is reading which Juz<br/>
                        &#9989; No more losing track of how many Tasbeehs are completed/remaining!</SubP>

                    <h4>How do I access the Quraan Tracker App&#10067;</h4>

                    <SubP>&#55357;&#56393;&#55356;&#57340; To open the app, use the link you've been
                        forwarded for a khatam</SubP>

                    <h5>Always use the same browser to open the app when clicking on a khatam
                        link</h5>

                    <p style={{fontSize: "0.9em", fontStyle: "italic"}}> If you use a different browser, you will not find your saved Khatams&#10071;</p>

                    <p>&#55357;&#56493; If you're asked to enter your name again, you're probably using a new
                        browser or your browser may be in Private / Incognito mode!</p>

                    <SubP>&#55357;&#56393;&#55356;&#57340; The first time you open the app, you will be required to enter
                        your
                        name. Your name will be visible to others when you reserve a Juz or when you submit your tasbeeh
                        count.</SubP>

                    <p style={{fontSize: "0.9em", fontStyle: "italic"}}>N.B: We do not ask you to login so if you do change phones or your Web Browser, you will not be
                        able
                        to access your saved Khatams on your new phone or browser!</p>

                    <h5>&#55356;&#57167; iOS Users</h5>
                    <SubP>
                        &#55357;&#56598; You can bookmark this site to easily go back to the app using your
                        preferred browser. Do not use the Add to Home Screen option!</SubP>

                    <SubP>To get back to the app, go to your bookmarks and click the saved Quraan.io bookmark</SubP>

                    <h5>&#55358;&#56598; Android Users</h5>
                    <SubP>
                        You may see an option pop up to Add to Home Screen. You may select this which will install the
                        Quraan Tracker app to your Home Screen/Applications. Alternatively once you've opened the app,
                        click
                        the menu button in your browser and click Add/Install to Home Screen</SubP>

                    <SubP>To get back to the app, click on the Quraan Tracker app on your Home Screen or in your App
                        menu</SubP>

                    <h4>What do I do when I receive a link to a Khatam&#10067;</h4>

                    <p style={{fontSize: "0.9em", fontStyle: "italic"}}>&#55357;&#56393;&#55356;&#57340;
                        If it's the first time you're opening the app, 1st follow the
                        instructions above</p>

                    <SubP>1. Open the link using the same browser as before.</SubP>

                    <SubP>If you're an Android user and have saved the app to your home screen, it will give you the option
                        to
                        open with the App instead of your browser. Select open with the app ALWAYS</SubP>

                    <SubP>2. On the top right hand side, there will be a white outlined heart. Click on the heart -
                        it
                        will turn to a solid white heart &#55358;&#56589;. This means you've save the khatam. If you've
                        completed a Khatam you can click the solid white heart again and it will be removed from your
                        list
                        of saved Khatams</SubP>

                    <p style={{fontSize: "0.9em", fontStyle: "italic"}}>Saving a Khatam allows you to easily be able to go back to the Khatam without having to look for
                        the
                        link that was shared with you for that Khatam. Just open the app using the bookmark you saved
                        earlier (iOS) it the app on your phone screen (Android), then from the main menu you can select
                        Quraan/Tasbeeh and you will find your saved Khatams</p>

                    <h4>Quraan Khatam</h4>

                    <SubP>1. Click RESERVE next to the Juz you wish to read. If you have successfully reserved the
                        Juz,
                        the Juz will show your name as well as a flashing ✓.</SubP>

                    <SubP>2. Once you have completed reading your Juz, click on the ✓ to mark the Juz as
                        complete.
                        You can also do this using the 3 dots on the right of the Juz</SubP>

                    <SubP>N.B: The number of sujood in the Juz (if any) is indicated in brackets next to the Juz for your
                        convenience</SubP>

                    <h4>Tasbeeh Khatam</h4>

                    <SubP>1. When opening a Tasbeeh Khatam, the first tab will show you the intention and the Tasbeeh
                        to
                        recite.</SubP>

                    <SubP>2. Move to the second tab by swiping from right to left of clicking the Counter tab</SubP>

                    <SubP>3. You can either use the built in counter to count your Tasbeehs or you can click the 3
                        dots
                        on the top left and select Enter Manually. Here you can enter how many Tasbeeh you have
                        completed
                        and click on SET COUNT</SubP>

                    <SubP>4. Click on SUBMIT on the top right to submit your Tasbeeh count.</SubP>

                    <SubP>5. You can see your submitted Tasbeeh count by clicking on the HISTORY tab</SubP>

                    <h4>Starting a new Khatam</h4>

                    <SubP>1. From the main menu, select Quraan or Tasbeeh</SubP>

                    <SubP>2. Click on the + on the bottom right</SubP>

                    <SubP>3. Fill in all the fields as required</SubP>

                    <SubP>4. Click NEXT</SubP>

                    <SubP>5. Once you're in the Khatam, you can click the share button on the top right (next to
                        the &#55358;&#56589; icon) to share the Khatam with others</SubP>
            <Footer/>
                </HelpText>

            </ContentContainer>
            <MadeBy/>
        </Container>
    );
};

export default Help;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
`;

const ContentContainer = styled.div`
    width: 90%;
    height: 90%;
    box-sizing:border-box;
    margin: 5% 5%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
`;

const HelpText = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const SubP = styled.p`
  font-size: 0.8em;
`;